<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-13 15:13:51
-->
<template>
  <div>
    <div class="bian">
      <el-popover
        placement="left-start"
        v-if="
          (specialArea == 31 && userInfo.isMakerSpace == 0) || specialArea != 31
        "
        width="412"
        offset="33"
        popper-class="area_popper"
        :visible-arrow="false"
        @show="carShow"
        ref="popover"
        trigger="click"
      >
        <div class="pop_t">
          <div class="pop_tt">
            <!-- <div class="kong"></div> -->
            <div>{{ $t("N_I_194") }}</div>
            <!-- <img src="@/assets/images/x.png"
                   alt=""> -->
          </div>
          <div class="pop_tb">
            <div
              class="pop_i"
              v-for="(item, index) in shopCarList[0].shoppingCartList"
              :key="index"
            >
              <img :src="item.cover1" alt="" />
              <div class="pop_ill">
                <div class="tit1 pop_illd">{{ item.waresName }}</div>
                <div class="dis">
                  <span class="tit2"
                    >{{ userInfo.currencyIcon
                    }}{{ item.waresPrice | numberToCurrency }}</span
                  >
                  <span class="tit3"
                    >{{ $t("ENU_TOTAL_V_1") }}:{{
                      item.achieve | numberToCurrency
                    }}</span
                  >
                  <span
                    class="tit3"
                    v-show="shopCarList[0].specialArea == 13"
                    style="margin-left: 10px"
                    >BV:{{ item.assAchieve | numberToCurrency }}</span
                  >
                </div>
                <div class="pop_ilb">
                  <el-input-number
                    v-model="item.number"
                    size="mini"
                    @change="changeCar(item, index)"
                    :min="1"
                  ></el-input-number>

                  <el-popover
                    placement="bottom"
                    v-if="shopCarList[0].specialArea != 21"
                    trigger="click"
                  >
                    <div
                      v-for="(stem, sndex) in item.productParams"
                      :key="sndex"
                      class="tab_i2i"
                    >
                      <div class="tab_i2il">
                        {{ stem.productName
                        }}{{
                          stem.waresItemsParamsList.length > 0 &&
                          stem.waresItemsParamsList[0].specsName
                        }}
                      </div>
                      <div class="tab_i2ir">
                        *{{ stem.waresItemsParamsList[0].quantity }}
                      </div>
                    </div>
                    <div slot="reference">{{ $t("w_0162") }}</div>
                  </el-popover>
                  <el-popover placement="bottom" v-else trigger="click">
                    <div
                      v-for="(stem, sndex) in item.waresSkuList"
                      :key="sndex"
                      class="tab_i2i"
                    >
                      <div class="tab_i2il">{{ stem.specValueNames }}</div>
                    </div>
                    <div slot="reference">{{ $t("w_0162") }}</div>
                  </el-popover>
                </div>
              </div>

              <div class="delImg" @click="delData(item)">
                <img src="@/assets/images/delImg.png" alt="" />
              </div>
              <!-- <div class="tit4"
                     @click="delData(item)">删除</div> -->
            </div>
          </div>
        </div>
        <div class="pop_b">
          <div class="tit5">
            <div class="pop_bl">
              <div class="pop_bl">
                {{ $t("w_0325") }}:<span class="tit2"
                  >{{ userInfo.currencyIcon
                  }}{{ totalPrice | numberToCurrency }}</span
                >
              </div>
              <div class="pop_bl">
                {{ $t("ENU_TOTAL_V_1") }}:<span class="tit3"
                  >{{ userInfo.pkCountry == 1 ? "￥" : "$"
                  }}{{ totalAchive | numberToCurrency }}</span
                >
              </div>
              <div class="pop_bl" v-show="shopCarList[0].specialArea == 13">
                BV:<span class="tit3"
                  >{{ userInfo.currencyIcon
                  }}{{ totalBv | numberToCurrency }}</span
                >
              </div>
            </div>

            <!-- <div style="margin-top:10px">{{ howLevel }}</div> -->
          </div>
          <div class="pop_br" @click="toBuy">{{ $t("w_0163") }}</div>
        </div>
        <div class="bian_i" ref="shoppCar" slot="reference">
          <div class="dian_f">
            <img src="@/assets/images/car.jpg" alt="" />
            <div class="dian">{{ specialCarNum }}</div>
          </div>
          <div>{{ $t("N_I_194") }}</div>
        </div>
      </el-popover>
     <!-- <div class="bian_i" @click="openKf">
        <img src="@/assets/images/lxkf.jpg" alt="" />
        <div>{{ $t("N_I_190") }}</div>
      </div> -->
      <!-- v-show="shareShow&&userInfo.pkSettleCountry==1" -->
      <!-- <div class="bian_i" @click="showShare">
        <img src="@/assets/images/fx.jpg" alt="" />
        <div>{{ $t("w_0009") }}</div>
      </div> -->
      <div class="bian_i" v-show="goTopShow" @click="toTop">
        <img src="@/assets/images/hddb.jpg" alt="" />
        <div>{{ $t("w_0164") }}</div>
      </div>
    </div>
    <!-- 嗨粉分享 -->
    <div class="shareDig">
      <el-dialog
        title=""
        width="512px"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
      >
        <div
          class="shareClass"
          id="myImage1"
          style="height: 414px; text-align: center"
        >
          <img class="shareImg" :src="shareImg" alt="" />
          <div class="shareCode">{{ userInfo.memberCode }}</div>
        </div>
        <img
          class="shareImg3"
          @click="saveImg1"
          src="@/assets/images/btnShare.png"
          alt=""
        />
      </el-dialog>
    </div>
    <!-- 海粉分享 -->
    <div class="shareDig">
      <el-dialog
        title=""
        width="1400px"
        :visible.sync="dialogVisible1"
        :before-close="handleClose1"
      >
        <div
          class="shareClass1"
          id="myImage"
          style="height: 520px; text-align: center"
        >
          <img class="shareImg1" :src="shareImg" alt="" />
        </div>
        <img
          class="shareImg2"
          @click="saveImg"
          src="@/assets/images/btnShare.png"
          alt=""
        />
      </el-dialog>
    </div>
    <el-dialog
      title="赠品列表"
      :visible.sync="dialogVisible2"
      width="50%"
      :before-close="handleClose2"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        height="400px"
        :header-cell-style="{ background: '#EEEEEE' }"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="cover1" label="商品图片">
          <template slot-scope="scope">
            <img class="bgImg" :src="scope.row.cover1" alt="" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="waresName" label="商品名称">
        </el-table-column>
        <el-table-column align="center" prop="waresCode" label="商品编号">
        </el-table-column>
        <el-table-column align="center" prop="waresPrice" label="商品价格">
        </el-table-column>
        <el-table-column align="center" prop="" label="数量">
          <template slot-scope="scope">
            <el-input-number
              size="mini"
              @change="calPrice"
              v-model="scope.row.quantity"
              :min="1"
            ></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">合计:{{ total | toThousandthAndKeepDecimal }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="tableTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

import * as api from "@/api/goods.js";
import { memLevel } from "@/api/register.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCar: false,
      shopCarList: [
        {
          shoppingCartList: [],
        },
      ],
      goodsCheck: [],
      totalPrice: 0,
      totalAchive: 0,
      totalBv: 0,
      specialArea: "",
      howLevel: "",
      goTopShow: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      shareImg: "",
      tableData: [],
      selTable: [],
      total: "0.00",
    };
  },
  props: {
    showCar: false,
    shareShow: false,
  },
  computed: {
    ...mapGetters(["userInfo", "specialCarNum"]),
  },
  mounted() {
    window.addEventListener("scroll", this.showIcon);
  },
  methods: {
    getSpecial(specialArea) {
      this.specialArea = specialArea;
    },
    saveImg() {
      const options = {
        backgroundColor: null,
      };
      html2canvas(document.querySelector("#myImage"), options).then(
        (canvas) => {
          let url = canvas.toDataURL("image/png");
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "sharePost");
          a.click();
        }
      );
    },
    saveImg1() {
      const options = {
        backgroundColor: null,
      };
      html2canvas(document.querySelector("#myImage1"), options).then(
        (canvas) => {
          let url = canvas.toDataURL("image/png");
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "sharePost");
          a.click();
        }
      );
    },
    openKf() {
      let src =
        "https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000033467&chatId=302384fb-eda1-436e-a5e9-c03a2dbd6e97&visitorId=" +
        this.userInfo.memberCode +
        "&nickName=" +
        this.userInfo.memberCode;
      window.open(src, "_blank");
    },
    showIcon() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > document.body.clientHeight - 200) {
        //设置页面滑动多少显示按钮
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
    closePopover() {
      this.$refs.popover.doClose();
    },
    carShow(e) {
      this.$emit("showCar");
    },
    showShare() {
      this.$emit("showShare");
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose1() {
      this.dialogVisible1 = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
    },
    tableTrue() {
      if (this.selTable.length > 0) {
        let a = {
          specialArea: this.goodsCheck[0].specialArea,
          orderItemsParams: [],
        };
        a.orderItemsParams = this.selTable.map((item) => {
          return {
            waresCode: item.waresCode,
            quantity: item.quantity,
            waresItemsParamList: item.productGroup,
            energySilo: 1,
          };
        });
        a.orderItemsParams.forEach((item) => {
          item.waresItemsParamList = item.waresItemsParamList.map((ctem) => {
            return {
              pkWaresSpecsSku: ctem.pkSkuId,
              quantity: ctem.quantity,
            };
          });
        });
        api.energyShopSilo(a).then((res) => {
          if (res.code == 200) {
            this.dialogVisible2 = false;
            if (this.goodsCheck[0].specialArea == 1) {
              this.$router.push({
                path: "register",
                query: {
                  specialArea: this.goodsCheck[0].specialArea,
                  selTable: JSON.stringify(this.selTable),
                },
              });
            } else if (this.goodsCheck[0].specialArea == 2) {
              this.$router.push({
                path: "upgrade",
                query: {
                  specialArea: this.goodsCheck[0].specialArea,
                  selTable: JSON.stringify(this.selTable),
                },
              });
            }
          }
        });
      } else {
        this.$message({
          message: "请选择赠品",
          type: "warning",
        });
      }
    },
    handleSelectionChange(val) {
      // waresPrice
      let total = 0;
      val.forEach((item) => {
        total += Number(item.waresPrice) * item.quantity;
      });
      this.total = total;
      this.selTable = val;
    },
    calPrice(e) {
      let total = 0;
      this.selTable.forEach((item) => {
        total += Number(item.waresPrice) * item.quantity;
      });
      this.total = total;
    },
    getShare(specialArea) {
      if (specialArea == 7) {
        this.dialogVisible = true;
        api.fansCode().then((res) => {
          this.shareImg = "data:image/png;base64," + res.data.dataStr;
        });
      } else {
        this.dialogVisible1 = true;
        api.shareCode().then((res) => {
          this.shareImg = "data:image/png;base64," + res.data.dataStr;
        });
      }
    },
    toBuy() {
      let shoppArr = this.goodsCheck[0].orderItemsParams.map((item) => {
        return item.shoppingId;
      });
      localStorage.setItem("allGoods", "");
      localStorage.setItem("allGoods", JSON.stringify(shoppArr));
      if (
        this.goodsCheck[0].specialArea == 3 ||
        this.goodsCheck[0].specialArea == 12 ||
        this.goodsCheck[0].specialArea == 14 ||
        this.goodsCheck[0].specialArea == 22 ||
        this.goodsCheck[0].specialArea == 26 ||
        this.goodsCheck[0].specialArea == 28
        
      ) {
        this.$router.push({
          path: "repurchase",
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        });
      }else if(this.goodsCheck[0].specialArea == 31){
        if (this.totalPrice < 1000) {
          this.$message({
            message: "购买商品总价格不能低于1000元",
            type: "warning",
          });
          return false;
        }else{
          this.$router.push({
          path: "repurchase",
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        });
        }
      } else if (this.goodsCheck[0].specialArea == 30) {
        if (this.goodsCheck[0].orderItemsParams.length > 1) {
          this.$message({
            message: "续约专区每次只能购买一件商品",
            type: "warning",
          });
          return false;
        } else {
          let num = this.goodsCheck[0].orderItemsParams.some(
            (item) => item.quantity > 1
          );
          if (num) {
            this.$message({
              message: "续约专区每次只能购买一件商品",
              type: "warning",
            });
            return false;
          } else {
            this.$router.push({
              path: "repurchase",
              query: {
                specialArea: this.goodsCheck[0].specialArea,
              },
            });
          }
        }
      } else if (this.goodsCheck[0].specialArea == 13) {
        this.$router.push({
          path: "welfare",
        });
      } else if (this.goodsCheck[0].specialArea == 14) {
        this.$router.push({
          path: "tvIng",
        });
      } else if (this.goodsCheck[0].specialArea == 11) {
        this.$router.push({
          path: "integral",
        });
      } else if (this.goodsCheck[0].specialArea == 10) {
        this.$router.push({
          path: "rescission",
        });
      } else if (
        this.goodsCheck[0].specialArea == 1 ||
        this.goodsCheck[0].specialArea == 24
      ) {
        let deleteList = [];
        shoppArr.forEach((item) => {
          deleteList.push({
            shoppingId: item,
          });
        });
        api
          .validShop({
            specialArea: this.goodsCheck[0].specialArea,
            deleteList: deleteList,
          })
          .then((res) => {
            api
              .energyShop({
                specialArea: this.goodsCheck[0].specialArea,
                deleteList: deleteList,
              })
              .then((response) => {
                if (response.data && response.data.length > 0) {
                  this.tableData = response.data;
                  this.tableData.forEach((item) => {
                    this.$set(item, "quantity", 1);
                  });
                  this.dialogVisible2 = true;
                } else {
                  this.$router.push({
                    path: "register",
                    query: {
                      specialArea: this.goodsCheck[0].specialArea,
                    },
                  });
                }
              });
          });
      } else if (
        this.goodsCheck[0].specialArea == 25 ||
        this.goodsCheck[0].specialArea == 27
      ) {
        this.$router.push({
          path: "upgrade",
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        });
      } else if (this.goodsCheck[0].specialArea == 2) {
        // let deleteList = []
        // shoppArr.forEach((item) => {
        //   deleteList.push({
        //     shoppingId: item,
        //   })
        // })
        // api
        //   .validShop({
        //     specialArea: this.goodsCheck[0].specialArea,
        //     deleteList: deleteList,
        //   })
        //   .then((res) => {
        //     if (res.code == 200) {
        //       this.$router.push({
        //         path: 'upgrade',
        //         query: {
        //           specialArea: this.goodsCheck[0].specialArea,
        //         },
        //       })
        //     } else {
        //       this.$message({
        //         type: 'warning',
        //         message: res.msg,
        //       })
        //     }
        //   })
        let deleteList = [];
        shoppArr.forEach((item) => {
          deleteList.push({
            shoppingId: item,
          });
        });
        api
          .validShop({
            specialArea: this.goodsCheck[0].specialArea,
            deleteList: deleteList,
          })
          .then((res) => {
            api
              .energyShop({
                specialArea: this.goodsCheck[0].specialArea,
                deleteList: deleteList,
              })
              .then((response) => {
                if (response.data && response.data.length > 0) {
                  this.tableData = response.data;
                  this.tableData.forEach((item) => {
                    this.$set(item, "quantity", 1);
                  });
                  this.dialogVisible2 = true;
                } else {
                  this.$router.push({
                    path: "upgrade",
                    query: {
                      specialArea: this.goodsCheck[0].specialArea,
                    },
                  });
                }
              });
          });
      } else if (
        this.goodsCheck[0].specialArea == 7 ||
        this.goodsCheck[0].specialArea == 101
      ) {
        this.$router.push({
          path: "hiFans",
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        });
      } else if (this.goodsCheck[0].specialArea == 18) {
        this.$router.push({
          path: "spaceRegister",
          query: {
            specialArea: 18,
          },
        });
      } else if (this.goodsCheck[0].specialArea == 19) {
        this.$router.push({
          path: "spaceRegister",
          query: {
            specialArea: 19,
          },
        });
      } else if (this.goodsCheck[0].specialArea == 21) {
        this.$router.push({
          path: "shareConfirm",
          query: {
            specialArea: 21,
            allData: JSON.stringify(this.goodsCheck[0].orderItemsParams),
          },
        });
      }
    },
    goPay() {
      let arr = [];
      this.shopCarList.forEach((item) => {
        item.shoppingCartList.forEach((ctem) => {
          arr.push({
            specialArea: item.specialArea,
            orderItemsParams: [
              {
                waresCode: ctem.waresCode,
                waresName: ctem.waresName,
                achieve: ctem.achieve,
                assAchieve: ctem.assAchieve,
                shoppingId: ctem.shoppingId,
                waresPrice: ctem.waresPrice,
                cover1: ctem.cover1,
                source: ctem.source,
                quantity: ctem.number,
                waresItemsParamList: ctem.waresItemsParamList,
                waresSkuList: ctem.waresSkuList || [],
              },
            ],
          });
        });
      });
      const arrayHashmap = arr.reduce((obj, item) => {
        obj[item.specialArea]
          ? obj[item.specialArea].orderItemsParams.push(
              ...item.orderItemsParams
            )
          : (obj[item.specialArea] = item);
        return obj;
      }, {});
      this.goodsCheck = Object.values(arrayHashmap);
      console.log(
        "%c [ this.goodsCheck ]-346",
        "font-size:13px; background:#56745d; color:#9ab8a1;",
        this.goodsCheck
      );
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 20);
    },
    // 修改数量
    changeCar(item) {
      api
        .plusReduceShopping({
          shoppingId: item.shoppingId,
          number: item.number,
          specialArea: this.shopCarList[0].specialArea,
          pkCountry: Number(localStorage.getItem("pkCountry")),
        })
        .then((res) => {
          this.dealData();
          this.$store.dispatch("SpecialQuantity", {
            specialArea: this.shopCarList[0].specialArea,
            pkCountry: Number(localStorage.getItem("pkCountry")),
          });
        })
        .catch((err) => {
          this.getCarList(this.specialArea);
        });
    },
    getCarList(specialArea) {
      this.specialArea = specialArea;
      this.howLevel = "";
      this.totalPrice = 0;
      this.totalAchive = 0;
      this.totalBv = 0;
      api
        .getShopping({
          specialArea: specialArea,
          pkCountry: Number(localStorage.getItem("pkCountry")),
        })
        .then((res) => {
          if (res.data.length == 0) {
            this.shopCarList = [{ shoppingCartList: [] }];
            return false;
          } else {
            res.data.forEach((item) => {
              item.shoppingCartList.forEach((ctem) => {
                ctem.waresItemsParamList = [];
                if (item.specialArea != 21) {
                  if (ctem.productParams) {
                    ctem.productParams.forEach((stem) => {
                      if (stem.waresItemsParamsList.length > 0) {
                        stem.waresItemsParamsList[0].pkWaresSpecsSku =
                          stem.waresItemsParamsList[0].pkSkuId;
                        stem.waresItemsParamsList[0].productName =
                          stem.productName;
                        ctem.waresItemsParamList.push(
                          stem.waresItemsParamsList[0]
                        );
                      }
                    });
                  }
                }
              });
            });
            this.shopCarList = res.data;
            console.log(
              "%c [ this.shopCarList ]-494",
              "font-size:13px; background:#6936bf; color:#ad7aff;",
              this.shopCarList
            );

            this.goPay();
            this.dealData();
          }
        });
    },
    // 计算价格
    checkPrice() {
      let totalPrice = 0;
      let totalAchive = 0;
      let totalBv = 0;
      this.goodsCheck.forEach((item) => {
        item.orderItemsParams.forEach((ctem) => {
          totalPrice += parseFloat(ctem.waresPrice * ctem.quantity);
          totalAchive += parseFloat(ctem.achieve * ctem.quantity);
          totalBv += parseFloat(ctem.assAchieve * ctem.quantity);
        });
      });
      this.totalPrice = totalPrice.toFixed(2);
      this.totalAchive = totalAchive.toFixed(2);
      this.totalBv = totalBv.toFixed(2);
    },
    // 处理数据
    dealData() {
      let arr = [
        {
          specialArea: this.shopCarList[0].specialArea,
          orderItemsParams: [],
        },
      ];
      this.shopCarList[0].shoppingCartList.forEach((ctem) => {
        arr[0].orderItemsParams.push({
          waresCode: ctem.waresCode,
          waresName: ctem.waresName,
          shoppingId: ctem.shoppingId,
          achieve: ctem.achieve,
          assAchieve: ctem.assAchieve,
          waresPrice: ctem.waresPrice,
          cover1: ctem.cover1,
          source: ctem.source,
          quantity: ctem.number,
          waresItemsParamList: ctem.waresItemsParamList,
          waresSkuList: ctem.waresSkuList || [],
        });
      });
      this.goodsCheck = arr;
      this.checkPrice();
    },
    // 多删
    delData(item) {
      let deleteList = [{ shoppingId: item.shoppingId }];
      api
        .batchDelShopping({
          deleteList: deleteList,
          pkCountry: Number(localStorage.getItem("pkCountry")),
        })
        .then((res) => {
          this.getCarList(this.specialArea);
          this.$store.dispatch(
            "SpecialQuantity",

            {
              specialArea: this.shopCarList[0].specialArea,
              pkCountry: Number(localStorage.getItem("pkCountry")),
            }
          );
          this.$message({
            type: "success",
            message: this.$t("w_0089") + "!",
          });
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.bian {
  position: fixed;
  width: 86px; // height: 425px;
  background: #ffffff;
  box-shadow: -8px 1px 20px 0px rgba(165, 165, 165, 0.5);
  top: 60%;
  transform: translate(0, -50%);
  right: 0;
  padding: 40px 0 0 0;
  text-align: center;
  z-index: 100;
  border-radius: 20px;
}

.bian_i {
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  img {
    width: 25px;
    height: 25px;
  }
}

.dian_f {
  position: relative;
  display: inline-block;
  .dian {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    position: absolute;
    color: #fff;
    background: #fe0000;
    top: -10px;
    right: -10px;
    border-radius: 50%;
  }
}

.pop_t {
  padding: 20px;
  .pop_tt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    div {
      margin: 0 auto;
    } // .kong {
    //   width: 22px;
    // }
    // img {
    //   width: 22px;
    //   height: 22px;
    // }
  }
  .pop_tb {
    padding: 34px 0px 0 0;
    max-height: 320px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .pop_i {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      position: relative;
      img {
        width: 81px;
        height: 92px;
        margin-right: 10px;
      }
      .pop_ill {
        flex: 1;
        .pop_illd {
          padding-right: 40px;
        }
        .pop_ilb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          div {
            cursor: pointer;
          }
        }
      }
      .delImg {
        position: absolute;
        right: 0;
        top: 0;
        img {
          width: 27px;
          height: 27px;
        }
      }
    }
  }
}

.pop_b {
  padding: 10px 10px;
  background: #ededed;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pop_bl {
    // display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .pop_br {
    width: 117px;
    height: 38px;
    background: #d61820;
    border-radius: 8px 8px 8px 8px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
  }
}

.tab_i2i {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tab_i2il {
  }
  .tab_i2ir {
    color: #d61820;
    margin-left: 10px;
  }
}

::v-deep .shareDig .el-dialog__header {
  padding: 0;
}

::v-deep .shareDig .el-dialog__body {
  padding: 0; // background-size: cover;
}

::v-deep .el-popover {
  padding: 0 !important;
  border-radius: 20px;
}

::v-deep .shareDig .el-dialog {
  border-radius: 10px;
}

.shareClass {
  border-radius: 10px 10px 10px 10px;
  background-image: url("../assets/images/sm.jpg");
  background-size: cover; // text-align: center;
  position: relative;
}

.saveHi {
  position: absolute;
  bottom: 0;
}

.shareClass1 {
  border-radius: 10px 10px 10px 10px;
  background-image: url("../assets/images/sm2.jpeg");
  background-size: cover; // text-align: center;
  position: relative;
}

.shareImg {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 10px;
  right: 0;
  margin: 0 auto;
  bottom: 12%;
}
.shareCode {
  position: absolute;
  left: 10px;
  right: 0;
  margin: 0 auto;
  bottom: 6%;
  font-size: 18px;
  font-weight: 600;
}
.shareImg1 {
  width: 180px;
  height: 180px;
  position: absolute;
  bottom: 30%;
  left: 18%;
  right: 0%;
  margin: auto;
}

.shareImg2 {
  width: 180px;
  height: 60px;
  position: absolute;
  bottom: 15%;
  left: 18%;
  right: 0%;
  margin: auto;
  animation: wiggle 1s ease-in-out infinite;
}

.shareImg3 {
  width: 180px;
  height: 60px;
  position: absolute;
  margin: 0 auto;
  animation: wiggle 1s ease-in-out infinite;
  left: 0;
  right: 0;
  bottom: -20%;
}

.tit1 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.tit2 {
  font-size: 21px;
  font-weight: 600;
  color: #f43c3a;
  margin-right: 10px;
}

.tit3 {
  font-size: 14px;
  color: #384a8a;
}

.tit4 {
  font-size: 12px;
  font-weight: 400;
  color: #d2171f;
  cursor: pointer;
}

.tit5 {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.dis {
  display: flex;
  align-items: center;
  margin: 5px 10px 5px 0;
}
.bgImg {
  width: 48px;
  height: 48px;
}
@keyframes wiggle {
  0% {
    transform: scale(1.1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.1);
  }
}
.total {
  text-align: right;
  font-size: 14px;
  padding: 5px 10px;
}
</style>

<style scoped></style>

<style>
.area_popper {
  border-radius: 30px !important;
  padding: 0 !important;
}
</style>
